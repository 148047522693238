import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from '../components/seo';

const Indywidualna = () => {
    return (
        <Layout>
            <Seo title="Oferta Dla Firm"/>
            <section className="subof-container">
                <section className="subof-intro">
                    <StaticImage 
                        src='../images/main_third.jpg'
                        alt="architektura" 
                        loading="eager"
                        quality={90}
                        className='subof-intro_picture'
                    />
                    <h1 className="subof-intro_title">Oferta Dla Firm</h1>
                    <h2 className="subof-intro-subtitle">Programy edukacyjne oraz treningowe dla pracowników firm</h2>
                </section>
                <section className="subof-hero">
                    <p className="subof-hero_text">Dla pracowników firm prowadzimy zajęcia grupowe regularne (stacjonarne lub on-line) lub punktowe np. podczas wyjazdów integracyjnych lub dni zdrowia organizowanych w firmie.</p>
                    <p className="subof-hero_text">Prowadzimy wykłady edukacyjne, analizy postawy ciała oraz praktyczne programy prewencyjne dla zachowania zdrowia, dobrej formy i zminimalizowania dolegliwości wynikających np. z długotrwałej pracy siedzącej.</p>
                    <p className="subof-hero_text">Dla kobiet na urlopie macierzyńskim proponujemy pakiety zadbania o powrót do formy po porodzie i do lepszego samopoczucia przy powrocie do rytmu pracy.</p>
                    <p className="subof-hero_text">A co w Państwa firmie warto wdrożyć z obszaru Wellbeingu pracowników?</p>
                    <br/>
                    <p className="subof-hero_text">Nasza pełna oferta dla firm (do realizacji w Państwa siedzibie, w naszym Studio w zewnętrznej przestrzeni lub on-line) to między innymi:</p>
                    <ul className="subof-hero_list">
                        <li>Programy edukacyjne dla pracowników firm (w formie: wykładów, prezentacji, rozmów z wymianą doświadczeń, broszur, mailingów, podcastów, itp.)</li>
                        <li>Treningi grupowe (Pilates, Stretching, treningi neuroplastyczności, treningi oddechowe i relaksacyjne, treningi oczu, treningi dłoni i nadgarstków, itp.)</li>
                        <li>Indywidualne konsultacje dla pracowników firm w formie analizy postawy ciała i ergonomii środowiska pracy</li>
                        <li>Praktyczne programy profilaktyczne np. przeciwdziałanie dolegliwościom związanym z pracą siedzącą</li>
                        <li>Programy indywidualnych zajęć dla wybranych osób w firmie: zajęcia na układzie nerwowym i/lub oddechowym i/lub ruchowym</li>
                        <li>Aktywne przerwy podczas konferencji i wydarzeń firmowych</li>
                        <li>Wyjazdy integracyjne w duchu Digital Detox</li>
                    </ul>
                    <p className="subof-hero_text">Każdą ofertę budujemy pod konkretne potrzeby pracowników a także całej organizacji. Sprawdź przykładowe realizacje:</p>
                    <a href='/documents/Pilates-terapeutyczny-Studio_Oferta_Wellbeing_dla_firm_2024-2025.pdf' download="oferta Wellbeing dla firm" className="subof-hero_text subof_highlight subof-hero_text-underline subof-hero_text-bold">Ogólna oferta Wellbeing dla firm &#8628;</a>
                    <a href='/documents/Kwestionariusz_dla_firm.pdf' download="kwestionariusz dla firm" className="subof-hero_text subof_highlight subof-hero_text-underline subof-hero_text-bold">POBIERZ FORMULARZ DLA FIRM &#8628;</a>
                    <div className="sub-hero_link-container">
                        <Link to="/kontakt" className="sub-hero_link-link">KONTAKT</Link>
                    </div>
                </section>
            </section>
        </Layout>
    )
}

export default Indywidualna;